import {scrollStyles} from './maxs-scroll-magic';

var observer;

document.addEventListener("DOMContentLoaded", function() {

    const bg = document.querySelector('.hero .bg');
    const luna = document.querySelector('#luna-vector');
    const nav = document.querySelector('nav');
    const hamburger = document.querySelector('nav .hamburger-menu');
    const body = document.querySelector('body');
    let isNavOpen = false;

    hamburger.onclick = () => {
        if(isNavOpen){
            nav.classList.remove('open');
            nav.classList.add('closed');
        }else{
            nav.classList.add('open');
            nav.classList.remove('closed');
        }
        body.style.overflow = isNavOpen ? 'auto' : 'hidden';
        body.style.position = isNavOpen ? 'relative' : 'fixed';
        isNavOpen = !isNavOpen;
    };

    scrollStyles([{pageStart:0, pageEnd: 1, trackPixels:true, prop:"translate"}],
    ret => {
        let bgRounded = Math.round(ret.translate/4);
        if(bg)bg.style.transform = `translate3d(0,${bgRounded}px,0)`;
    });

    
    if(luna) createObserver();

    function createObserver() {
        observer;

        var options = {
            root: null,
            rootMargin: "80px 0px 0px 0px",
            threshold: [0, 0.5]
        };

        observer = new IntersectionObserver(handleIntersect, options);
        observer.observe(luna);
    }

      function handleIntersect(entries, observer) {
        entries.forEach(function(entry) {
            requestAnimationFrame(()=>{
                if(entry.intersectionRatio >= 0.5) entry.target.classList.remove('not-in-view');
                else entry.target.classList.add('not-in-view');
            });
            
        });
      }

});

window.onload = () => {
    if(location.hash){
        var elId = location.hash.replace('#','');
        var scrollToEl = document.getElementById(elId);
        scrollToEl.scrollIntoView(true);   
    }
}


require('./instant-page.js');