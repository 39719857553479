var scheduledAnimationFrame;

let lastScrollY = window.scrollY;
let intViewportHeight = window.innerHeight;

let pagePercent = ()=>(lastScrollY / intViewportHeight);

const throttledScroll = new CustomEvent('throttledScroll', {detail: {page: pagePercent}});
const throttledResize = new CustomEvent('throttledResize');

function readAndUpdatePage(){
	scheduledAnimationFrame = false;
	
  // Dispatch the event.
	window.dispatchEvent(throttledScroll);
}

function onScroll (e) {

  // Store the scroll value for laterz.
	lastScrollY = window.scrollY;

  // Prevent multiple rAF callbacks.
  if (scheduledAnimationFrame){
    return;
  }

  scheduledAnimationFrame = true;
  requestAnimationFrame(readAndUpdatePage);
}

let resizing;
function onResize (e){
	intViewportHeight = window.innerHeight;
	clearTimeout(resizing)
	resizing = setTimeout(()=>{
		requestAnimationFrame(()=>{
			window.dispatchEvent(throttledResize);
		})
	});
}

window.addEventListener('scroll', onScroll);
window.onload = onScroll();
window.addEventListener('resize', onResize);


export function scrollStyles(options, callback){
	let calculatedRatio=[];
	let returnVals={};

	options.forEach((v,i) =>{
		v['trackPixels'] = v['trackPixels'] || false;
		v['valueStart'] = v['valueStart'] || 0;
	});

	window.addEventListener('throttledScroll', (e)=>{
		options.forEach((v,i) =>{
			
			if(v.trackPixels){
				let currentPage = e.detail.page();
				if(currentPage < v.pageStart) returnVals[v.prop] = v.valueStart;
				else if (currentPage > v.pageEnd) returnVals[v.prop] = v.pageEnd * intViewportHeight;
				else if(currentPage >= v.pageStart && currentPage <= v.pageEnd) returnVals[v.prop] = lastScrollY;
			}
			
			else{
				calculatedRatio[i]= (v.pageEnd - v.pageStart) / (v.valueEnd - v.valueStart);
				let currentPage = e.detail.page();
				if(currentPage < v.pageStart) returnVals[v.prop] = v.valueStart;
				else if (currentPage > v.pageEnd) returnVals[v.prop] = v.valueEnd;
				else if(currentPage >= v.pageStart && currentPage <= v.pageEnd){
					let relativePage = currentPage - v.pageStart;
					returnVals[v.prop] = 
						v.valueStart+(relativePage / calculatedRatio[i]);
				}
			}
		});
		callback(returnVals);
	});
}

export function pageListener(callback){
	window.addEventListener('throttledScroll', (e)=>{
		callback(e.detail.page(), lastScrollY);
	});
}

// {
// 	pageStart: "",
// 	pageEnd: "",
// 	valueStart: "",
// 	valueEnd: "",
// }