require('offline-plugin/runtime').install();

//import './index.ejs';
import './index.scss';
import './scripts/script.js';
import './scripts/svg.js';


function importAll (r) {r.keys().forEach(r);}

importAll(require.context('./favicons/', true, /.*?/));

importAll(require.context('./images/', true, /.*?/));

importAll(require.context('./icons/', true, /.*?/));